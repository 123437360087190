import { render, staticRenderFns } from "./WootKeyShortcutModal.vue?vue&type=template&id=08a40f42&scoped=true&"
import script from "./WootKeyShortcutModal.vue?vue&type=script&setup=true&lang=js&"
export * from "./WootKeyShortcutModal.vue?vue&type=script&setup=true&lang=js&"
import style0 from "./WootKeyShortcutModal.vue?vue&type=style&index=0&id=08a40f42&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "08a40f42",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('08a40f42')) {
      api.createRecord('08a40f42', component.options)
    } else {
      api.reload('08a40f42', component.options)
    }
    module.hot.accept("./WootKeyShortcutModal.vue?vue&type=template&id=08a40f42&scoped=true&", function () {
      api.rerender('08a40f42', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/javascript/dashboard/components/widgets/modal/WootKeyShortcutModal.vue"
export default component.exports